// import {
//     useDispatch as useReduxDispatch,
//     useSelector as useReduxSelector
// } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

//import reducers from './reducers';
import myReducer from './myReducer';

const store = configureStore({
    reducer: myReducer,
    devTools: true
});

// export const useSelector = useReduxSelector;

// export const useDispatch = () => useReduxDispatch();

export default store;
