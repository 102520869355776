export const BOX_LAYOUT = 'BOX_LAYOUT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const RESET = 'RESET';
export const RTL_LAYOUT = 'RTL_LAYOUT';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALISE = 'ACCOUNT_INITIALISE';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// Ações criadas por mim.
export const CONSOLE_LOG_STATE = 'CONSOLE_LOG_STATE';

export const SALVA_ESTADO_USUARIO = 'SALVA_ESTADO_USUARIO';
export const CARREGA_ESTADO_USUARIO = 'CARREGA_ESTADO_USUARIO';
export const EXCLUI_ESTADO_USUARIO = 'EXCLUI_ESTADO_USUARIO';

export const SALVA_LISTA_INSTITUICOES = 'SALVA_LISTA_INSTITUICOES';
export const SALVA_LISTA_ESTADOS = 'SALVA_LISTA_ESTADOS';
export const SALVA_LISTA_MUNICIPIOS = 'SALVA_LISTA_MUNICIPIOS';
export const SALVA_ESTADO_SELECIONADO = 'SALVA_ESTADO_SELECIONADO';
export const SALVA_MUNICIPIO_SELECIONADO = 'SALVA_MUNICIPIO_SELECIONADO';
export const SALVA_VALOR_IMPORTANCIA_SALARIO = 'SALVA_VALOR_IMPORTANCIA_SALARIO';
export const SALVA_VALOR_IMPORTANCIA_EMPREGABLIDADE = 'SALVA_VALOR_IMPORTANCIA_EMPREGABILIDADE';
export const SALVA_RELEVANCIA_ENEM = 'SALVA_RELEVANCIA_ENEM';
export const SALVA_LISTA_PROFISSOES = 'SALVA_LISTA_PROFISSOES';
export const SALVA_LISTA_PROFISSOES_SELECIONADAS = 'SALVA_LISTA_PROFISSOES_SELECIONADAS';
export const SALVA_INDICADORES = 'SALVA_INDICADORES';
export const SALVA_RELATORIO_DETALHADO = 'SALVA_RELATORIO_DETALHADO';
export const SALVA_LISTA_CURSOS = 'SALVA_LISTA_CURSOS';
export const SALVA_LISTA_CODIGOS_CINE_ROTULO = 'SALVA_LISTA_CODIGOS_CINE_ROTULO';
export const SALVA_INFORMACOES_SISU = 'SALVA_INFORMACOES_SISU';
export const SALVA_LISTA_ATIVIDADES = 'SALVA_LISTA_ATIVIDADES';
export const SALVA_LISTA_FAMILIAS_OCUPACIONAIS = 'SALVA_LISTA_FAMILIAS_OCUPACIONAIS';
export const SALVA_LISTA_GACS = 'SALVA_LISTA_GACS';
export const SALVA_LISTA_OCUPACOES = 'SALVA_LISTA_OCUPACOES';
export const SALVA_LISTA_RECURSOS_TRABALHO = 'SALVA_LISTA_RECURSOS_TRABALHO';