const salvarEstado = (estado) => {
    try{
        localStorage.setItem('estado', JSON.stringify(estado));
    } catch (erro) {
        return undefined
    }
    
};

const carregarEstado = () => {
    try {
      const estado = localStorage.getItem('estado');
      if (estado === null) {
        return undefined;
      }
      return JSON.parse(estado);
    } catch (erro) {
      return undefined;
    }
  };

const excluirEstado = () => {
  try {
    localStorage.removeItem('estado');
  } catch (erro) {
    return undefined;
  }
};

const exports = {salvarEstado, carregarEstado, excluirEstado}

export default exports