import { 
    CONSOLE_LOG_STATE,
    SALVA_ESTADO_USUARIO,
    CARREGA_ESTADO_USUARIO,
    EXCLUI_ESTADO_USUARIO,
    SALVA_LISTA_INSTITUICOES,
    SALVA_LISTA_ESTADOS,
    SALVA_LISTA_MUNICIPIOS,
    SALVA_ESTADO_SELECIONADO,
    SALVA_MUNICIPIO_SELECIONADO,
    SALVA_VALOR_IMPORTANCIA_SALARIO,
    SALVA_VALOR_IMPORTANCIA_EMPREGABLIDADE,
    SALVA_RELEVANCIA_ENEM,
    SALVA_LISTA_PROFISSOES,
    SALVA_LISTA_PROFISSOES_SELECIONADAS,
    SALVA_INDICADORES,
    SALVA_RELATORIO_DETALHADO,
    SALVA_LISTA_CURSOS,
    SALVA_LISTA_CODIGOS_CINE_ROTULO,
    SALVA_INFORMACOES_SISU,
    SALVA_LISTA_ATIVIDADES,
    SALVA_LISTA_FAMILIAS_OCUPACIONAIS,
    SALVA_LISTA_GACS,
    SALVA_LISTA_OCUPACOES,
    SALVA_LISTA_RECURSOS_TRABALHO
} from "./actions";

import myInitialState from "./myInitialState";

import localStorage from "./localStorage";

const myReducer = (state = myInitialState, action) => {
    switch (action.type) {
        case CONSOLE_LOG_STATE: {
            console.log(state)
            return {
                ...state
            };
        }
        case SALVA_ESTADO_USUARIO: {
            localStorage.salvarEstado(state)
            return {
                ...state
            };
        }
        case CARREGA_ESTADO_USUARIO: {
            const estadoUsuario = localStorage.carregarEstado()
            return estadoUsuario;
        }
        case EXCLUI_ESTADO_USUARIO: {
            localStorage.excluirEstado()
            return {
                ...state
            };
        }
        case SALVA_LISTA_INSTITUICOES: {
            return {
                ...state,
                listaInstituicoes: action.list
            };
        }
        case SALVA_LISTA_ESTADOS: {
            return {
                ...state,
                listaEstados: action.list
            };
        }
        case SALVA_LISTA_MUNICIPIOS: {
            return {
                ...state,
                listaMunicipios: action.list
            };
        }
        case SALVA_ESTADO_SELECIONADO: {
            return {
                ...state,
                estadoSelecionado: action.value
            };
        }
        case SALVA_MUNICIPIO_SELECIONADO: {
            return {
                ...state,
                municipioSelecionado: action.value
            };
        }
        case SALVA_VALOR_IMPORTANCIA_SALARIO: {
            return {
                ...state,
                importanciaSalario: action.value
            };
        }
        case SALVA_VALOR_IMPORTANCIA_EMPREGABLIDADE: {
            return {
                ...state,
                importanciaEmpregabilidade: action.value
            };
        }
        case SALVA_RELEVANCIA_ENEM: {
            return {
                ...state,
                relevanciaEnem: action.value
            };
        }
        case SALVA_LISTA_PROFISSOES: {
            return {
                ...state,
                listaProfissoes: action.list
            };
        }
        case SALVA_LISTA_PROFISSOES_SELECIONADAS: {
            return {
                ...state,
                listaProfissoesSelecionadas: action.list
            };
        }
        case SALVA_INDICADORES: {
            return {
                ...state,
                indicadores: action.value
            };
        }
        case SALVA_RELATORIO_DETALHADO: {
            return {
                ...state,
                relatorioDetalhado: action.value
            };
        }
        case SALVA_LISTA_CURSOS: {
            return {
                ...state,
                listaCursos: action.list
            };
        }
        case SALVA_LISTA_CODIGOS_CINE_ROTULO: {
            return {
                ...state,
                listaCodigosCineRotulo: action.list
            };
        }   
        case SALVA_INFORMACOES_SISU: {
            return {
                ...state,
                listaInformacoesSisu: action.list
            };
        }  
        case SALVA_LISTA_ATIVIDADES: {
            return {
                ...state,
                listaAtividades: action.list
            };
        }  
        case SALVA_LISTA_FAMILIAS_OCUPACIONAIS: {
            return {
                ...state,
                listaFamiliasOcupacionais: action.list
            };
        }   
        case SALVA_LISTA_GACS: {
            return {
                ...state,
                listaGACS: action.list
            };
        }  
        case SALVA_LISTA_OCUPACOES: {
            return {
                ...state,
                listaOcupacoes: action.list
            };
        }
        case SALVA_LISTA_RECURSOS_TRABALHO: {
            return {
                ...state,
                listaRecursosTrabalho: action.list
            };
        }
        default:
            return state;
    }
};

export default myReducer;