const myInitialState = {
    listaInstituicoes: [],
    listaEstados: [],
    listaMunicipios: [],
    estadoSelecionado: undefined,
    municipioSelecionado: undefined,
    importanciaSalario: undefined,
    importanciaEmpregabilidade: undefined,
    relevanciaEnem: undefined,
    listaProfissoes: [],
    listaProfissoesSelecionadas: [],
    indicadores: {},
    relatorioDetalhado: {},
    listaCodigosCineRotulo: [],
    listaCursos: [],
    listaInformacoesSisu: [],
    listaAtividades: [],
    listaFamiliasOcupacionais: [],
    listaGACS: [],
    listaOcupacoes: [],
    listaRecursosTrabalho: []
};

export default myInitialState